import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {formCheckErrors, formGenerateErrorsObject} from "../../core/form";
import moment from "moment";
import Button from "../../components/ui/Button";
import VideoFiles from "../../components/ui/VideoFiles";
import {apiVideoCreate} from "../../actions/videoActions";
import {renderField} from "../../fields";
import { videoFields } from '../../fields/video';
import PageNotFound from "../pagenotfound/PageNotFound";
import Checkbox from "../../components/ui/Checkbox";

const errorsList = {
  title: 'required',
  description: 'required',
  location: 'location',
  categories: 'required'
}

const initialValues = {
  title: '',
  description: '',
  location: null,
  filmingDate: moment().toDate(),
  categories: [],
  tags: [],
  author: null,
  authorEmail: '',
  source: '',
  referrer: '',
  sensitive: false,
  violent: false,
  profanity: false,
}

function VideoUpload() {

  const token = useSelector(state => state.user.token)
  const user = useSelector(state => state.user.user)
  const navigate = useNavigate();

  const [files, setFiles] = useState([])
  const [values, setValues] = useState<any>(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [submitLoading, setSubmitLoading] = useState(false)
  const [fileError, setFileError] = useState(false)

  useEffect(() => {
    if (user?.role === 'admin') {
      setValues({
        ...values,
        categories: ['General news']
      })
    }
  }, [])

  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        })
        setErrors({
          ...errors,
          [key]: '',
        })
      }
    }
  }

  const submit = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }
    if (files.length === 0) {
      setFileError(true)
      return;
    }

    setSubmitLoading(true);

    try {

      const apiResponse = await apiVideoCreate(
        token,
        values.title,
        values.description,
        values.location,
        values.filmingDate,
        files,
        values.categories.join(','),
        values.tags.join(','),
        user?.role === 'admin' ? values.author?.user?.id : undefined,
        user?.role === 'admin' ? values.authorEmail : undefined,
        user?.role === 'admin' ? values.source : undefined,
        user?.role === 'admin' ? values.referrer : undefined,
        +values.sensitive,
        +values.violent,
        +values.profanity
      );
      if (apiResponse.status) {
        if (user?.role === 'stringer') {
          navigate(`/profile?tab=videos`)
        }
        if (user?.role === 'admin') {
          navigate(`/panel/videos/${apiResponse.video_id}`)
        }
      }

    } catch (error) {
      console.log(error);
    }

  }

  if (['admin', 'stringer'].indexOf(user?.role) === -1) {
    return <PageNotFound/>
  }

  // @ts-ignore
  return (
    <>
      <div className="video-page-top">
        <div onClick={() => {
          navigate(-1)
        }} className="video-page-top-back-icon">
          <i className="fa fa-angle-left"/>
        </div>
        <span className="video-page-top-title">Upload video</span>
        <div className="video-page-top-right">

        </div>
      </div>
      <div className="create-page-row row" style={{marginTop: '20px'}}>
        <div className="create-page-block">
          <div className="create-page-block-inner">
            {renderField({
              ...videoFields.title,
              ...getFieldActions('title'),
              className: "create-page-block-element",
              required: true,
            })}
            <div className="create-page-block-row row">
              <div className="create-page-block-row-col large">
                {renderField({
                  ...videoFields.location,
                  ...getFieldActions('location'),
                  className: "create-page-block-element",
                  required: true,
                })}
              </div>
              <div className="create-page-block-row-col small">
                {renderField({
                  ...videoFields.filmingDate,
                  ...getFieldActions('filmingDate'),
                  className: "create-page-block-element",
                  required: true,
                })}
              </div>
            </div>
            {renderField({
              ...videoFields.categories,
              ...getFieldActions('categories'),
              className: "create-page-block-element",
              required: true,
            })}
            {renderField({
              ...videoFields.tags,
              ...getFieldActions('tags'),
              className: "create-page-block-element",
            })}
            {user?.role === 'admin' && (
                <>
                  <div className="create-page-block-row row">
                    <div className="create-page-block-row-col">
                      {renderField({
                        ...videoFields.author,
                        ...getFieldActions('author'),
                        className: "create-page-block-element",
                        required: false
                      })}
                    </div>
                    <div className="create-page-block-row-col">
                      {renderField({
                        ...videoFields.authorEmail,
                        ...getFieldActions('authorEmail'),
                        className: "create-page-block-element",
                        required: false
                      })}
                    </div>
                  </div>
                  <div className="create-page-block-row row">
                    <div className="create-page-block-row-col">
                      {renderField({
                        ...videoFields.source,
                        ...getFieldActions('source'),
                        className: "create-page-block-element",
                        required: false
                      })}
                    </div>
                    <div className="create-page-block-row-col">
                      {renderField({
                        ...videoFields.referrer,
                        ...getFieldActions('referrer'),
                        className: "create-page-block-element",
                        required: false
                      })}
                    </div>
                  </div>
                </>
            )}
            <div className="create-page-block-element">
              <span className="custom-text-field-label">Warnings</span>
              <div className="create-page-block-row row">
                <Checkbox
                    label={'Sensitive content/blood images'}
                    checked={Boolean(values.sensitive)}
                    onChange={(checked) => {
                      setValues({
                        ...values,
                        sensitive: +checked,
                      })
                    }}
                    className="create-page-block-checkbox create-page-block-checkbox-no-margin"
                />
                <Checkbox
                    label={'Violent content'}
                    checked={Boolean(values.violent)}
                    onChange={(checked) => {
                      setValues({
                        ...values,
                        violent: +checked,
                      })
                    }}
                    className="create-page-block-checkbox create-page-block-checkbox-no-margin"
                />
                <Checkbox
                    label={'Video contains profanity'}
                    checked={Boolean(values.profanity)}
                    onChange={(checked) => {
                      setValues({
                        ...values,
                        profanity: +checked,
                      })
                    }}
                    className="create-page-block-checkbox create-page-block-checkbox-no-margin"
                />
              </div>
            </div>
            {renderField({
              ...videoFields.description,
              ...getFieldActions('description'),
              className: "create-page-block-element",
              required: true
            })}
            <div style={{height: '20px', width: '1px'}}/>
          </div>
        </div>
        <div className="create-page-block">
          <VideoFiles
              videoId={null}
              onChangeFiles={(files: any) => {
                setFiles(files)
                setFileError(false)
              }}
              initialFiles={files}
              clickable={false}
              moderation={false}
          />
          {fileError && <span className="video-upload-error">Upload video files</span>}
          <div className="video-upload-information">
            <span className="video-upload-information-title">Video upload requirements</span>
            <ul>
              <li>Please ensure that you insert the exact filming date and location (it’s necessary to specify the city
                where the video was filmed, not only the county).
              </li>
              <li>Ensure that the video lasts no less than 10 seconds and no more than 8 minutes.</li>
              <li>The video shouldn't have any watermarks or logo, no copyrighted music or excessive editing should be
                present in the video.
              </li>
              <li>Please give as many details as possible when filling in the description field.</li>
              <li>The recommended format is .MP4 (H.264). .MTS, .MOV, .AVI, .FLV video formats are acceptable but it might take longer time to process. A video can be shot on a camera or smartphone.</li>
            </ul>

          </div>
        </div>
      </div>
      <div className="bottom-area">
        <div className="bottom-area-inner">
          <div className="bottom-area-right">
            <Button
              className={'blue bottom-area-submit-button'}
              label={'Submit'}
              loading={submitLoading}
              onClick={submit}
              type={'button'}
            />
          </div>
        </div>
      </div>
    </>
  )


}

export default VideoUpload;
