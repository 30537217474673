import axios from "axios";
import {API_URL} from "../core/common";
import {Location} from "../components/ui/LocationSelect";
import moment from "moment";
import {TableLoadElementsParams} from "../components/table/TablePage";

export function apiVideoFileCreate(
  token: string,
  s3Path: string,
  filename: string,
  size: string,
  videoId: string | null
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video_file.create', {
      s3_path: s3Path,
      filename,
      size,
      video_id: videoId
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoFileChangeOrder(
  token: string,
  files: string
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video_file.change_order', {
      video_files: files
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoFileCompleteLoading(
  token: string,
  id: any
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video_file.complete_loading', {
      id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoFileDelete(
  token: string,
  id: any,
  moderation: number
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/video_file.delete?moderation=${moderation}`, {
      id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoFileRestore(
  token: string,
  id: any
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video_file.restore', {
      id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoPreview(
  token: string,
  video_id: any
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.preview', {
      video_id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoFileGenerateUploadLink(
  token: string,
  filename: string
) {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/video_file.generate_upload_link?filename=${filename}` , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoCreate(
  token: string,
  title: string,
  description: string,
  location: Location | null,
  filmingDate: Date,
  videoFiles: any,
  categories: string,
  tags: string,
  userId?: string,
  authorEmail?: string,
  source?: string,
  referrer?: string,
  sensitive?: number,
  violent?: number,
  profanity?: number
): any {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.create', {
      title,
      description,
      filming_date: moment(filmingDate).format("YYYY-MM-DD"),
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
      location_city: location?.location_city,
      video_files: videoFiles.map((file: any) => file.id).join(','),
      categories,
      tags,
      user_id: userId,
      author_email: authorEmail,
      source,
      referrer,
      sensitive,
      violent,
      profanity
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoEdit(
  token: string,
  id: number,
  title: string,
  description: string,
  location: Location | null,
  filmingDate: Date,
  videoFiles: any,
  categories: string,
  tags: string
): any {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.edit', {
      id,
      title,
      description,
      filming_date: moment(filmingDate).format("YYYY-MM-DD"),
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
      location_city: location?.location_city,
      video_files: videoFiles.map((file: any) => file.id).join(','),
      categories,
      tags
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoUpdate(
  token: string,
  video_id: string,
  title: string,
  title_partners: string,
  description: string,
  description_partners: string,
  location: Location | null,
  filmingDate: Date,
  videoFiles: any,
  categories: string,
  tags: string,
  author_email: string,
  inGallery: string,
  source: string,
  referrer: string
): any {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.update', {
      video_id,
      title,
      title_partners,
      description,
      description_partners,
      filming_date: moment(filmingDate).format("YYYY-MM-DD"),
      location_text: location?.location_text,
      location_place_id: location?.location_place_id,
      location_lat: location?.location_lat,
      location_lng: location?.location_lng,
      location_country: location?.location_country,
      location_city: location?.location_city,
      video_files: videoFiles.map((file: any) => file.id).join(','),
      public: inGallery,
      author_email,
      categories,
      tags,
      source,
      referrer
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideosGet(
  {
    cancelToken,
    token,
    search,
    filters,
    offset,
    limit,
    user_id = ''
  } : TableLoadElementsParams
) {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/videos.get?offset=${offset}&limit=${limit}&search=${search}&filters=${filters}&user_id=${user_id}` , {
      cancelToken,
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiVideoTagsSearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/tags.search?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.tags);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiVideoGet(
  token: string,
  video_id: string
) {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/video.find?id=${video_id}` , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoGetGallery(
  token: string,
  hash: string,
  galleryToken: string | null
) {

  return new Promise((resolve, reject) => {

    axios.get(`${API_URL}${token !== null ? '/gallery.find_access' : '/gallery.find'}?hash=${hash}${galleryToken !== null ? `&token=${galleryToken}` : ''}` , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideosGetGallery(token: string, search: string, filters: string, sort = '', offset = 0) {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/gallery.get?limit=32&offset=${offset}&search=${search}&filters=${filters}&sort=${sort}` , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoChangeStatus(
  token: string,
  video_id: any,
  status: number
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.change_status', {
      video_id,
      status
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoAccept(
  token: string,
  video_id: any
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.accept', {
      video_id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoDecline(
  token: string,
  video_id: any
) {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.decline', {
      video_id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoCopy(
  token: string,
  video_id: any
): any {
  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.copy', {
      video_id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}


export function apiVideoTagSearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/video.search_tags?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.tags);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiVideoCountrySearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/video.search_countries?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.countries);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}


export function apiVideoStateSearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/video.search_states?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.states);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiVideoCitiesSearch(cancelToken: any, input: string) {

  return new Promise<any[]>((resolve, reject) => {

    axios.get(API_URL + `/video.search_cities?text=${input}`, {
      cancelToken
    }).then(function (response) {
      resolve(response.data.cities);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

export function apiVideoChangeAuthor(
  token: string,
  video_id: any,
  user_id: any
): any {
  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.change_author', {
      video_id,
      user_id
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoMoveFile(
  token: string,
  video_id: any,
  file_id: any
): any {
  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.move_file', {
      video_id,
      file_id,
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiVideoDownload(
  token: string,
  video_id: any,
): any {
  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/video.download_catch', {
      video_id,
    } , {
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
